@font-face {
    font-family: 'Amsterdam Four';
    src: url('./amsterdam-four.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'Roboto', sans-serif;
    
  }
  
  